export const imageList = ['tile_58_35', 'tile_61_33', 'tile_27_23', 'tile_20_26'];

export const initialImages = [
    { id: 0, key: "tile_58_35", src: 'image/tile_58_35.png', alt: 'Image 1' },
    { id: 1, key: "tile_61_33", src: 'image/tile_61_33.png', alt: 'Image 2' },
    { id: 2, key: "tile_27_23", src: 'image/tile_27_23.png', alt: 'Image 3' },
    { id: 3, key: "tile_20_26", src: 'image/tile_20_26.png', alt: 'Image 3' },
  ];

export const KeyUpMsg = {
    87: "move_forward_done",
    65: "move_left_done",
    83: "move_back_done",
    68: "move_right_done"
};

export const keyDownMsg = {
    87: "move_forward",
    65: "move_left",
    83: "move_back",
    68: "move_right",
    32: "move_jump",
    86: "switch_view"
}

export const keyDownSightMsg = {
    "ArrowUp": "move_sight_top",
    "ArrowDown": "move_sight_down",
    "ArrowLeft": "move_sight_left",
    "ArrowRight": "move_sight_right",
}