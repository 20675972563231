// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth, GoogleAuthProvider, TwitterAuthProvider } from "firebase/auth";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAH4rinEP4Xmd7putWQNsPfik59diHLXTg",
  authDomain: "cybever-3d.firebaseapp.com",
  projectId: "cybever-3d",
  storageBucket: "cybever-3d.appspot.com",
  messagingSenderId: "118713039501",
  appId: "1:118713039501:web:e56da1cf2409cf6aea80c4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
// Initialize Firebase Auth provider
export const goolgeProvider = new GoogleAuthProvider();
export const twitterProvider = new TwitterAuthProvider();