import { useState, useRef, useEffect, useId } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from 'axios';
import { message, notification, Modal } from 'antd';
import { useUpdateEffect, useFullscreen } from "ahooks";
import ThreeScene from "@/components/ThreeScene/ThreeScene";
import DrawingBoard from "@/components/DrawingBoard/DrawingBoard";
import UEScene from "@/components/UEScene/UEScene";
import { createTask, revisionTask, taskStatus, confirmTask, closeTask, taskRender, getModelURL, randomMap, getTaskList, getVillageFile } from "@/service/taskService";
import { disabledButton, enabledButton, getImageSrcById, loadImage, loadReader, getLocalToken, updateState, downloadCheck, taskSwitchConfirm, checkUserWaitlist } from "@/lib/utils";
import { hudiniNotification, reconnectUESceneNotice, switchImageConfirm, ueNotification } from "@/lib/notification";
import { GenerateState, VillagePos, drawBoardRef } from "@/types";
import { initialImages } from "@/lib/sample-data";
import fullScreenIcon from "@/assets/fullscreen.svg";
import fullScreenExitIcon from "@/assets/fullscreen_exit.svg";
import downloadIcon from "@/assets/download.svg";
import "@/App.css";
import "./generate.css";
import UEProgress from "@/components/UEProgress";
import ModelProgress from "@/components/ModelProgress";
import WaitList from "@/components/WaitList";

const ASSET_MODEL = import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_ASSET_MODEL;
const MODEL = import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_DOWNLOAD_MODEL;

type Wait = {
    status: number,
    text: string
}

export default function Generate() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const drawBoardRef = useRef<drawBoardRef>(null);
    const [randomLoading, setRandomLoading] = useState<boolean>(false);
    const [genPersceneLoading, setGenPersceneLoading] = useState<boolean>(false);
    const [generateLoading, setGenerateLoading] = useState<boolean>(false);
    const [initImages, setImages] = useState(initialImages);
    const [perfectionScene, setPerfectionScene] = useState<boolean>(false);
    const [activeImageIndex, setActiveImageIndex] = useState<number>(-1);
    const [modelUrl, setModelUrl] = useState<string>("");
    const queryType = useRef<string>("model");
    const timerRef = useRef<string | number | undefined | NodeJS.Timeout>(undefined);
    const isModelDone = useRef<boolean>(false);
    const taskid = useRef<string>("");
    const [taskID, setTaskID] = useState<string>("");
    const taskTempStatus = useRef<number>(0);
    const isRevision = useRef<boolean>(false);
    const generateState = useRef<GenerateState>({ text: "null", time: 0, total_time: 0, value: 0, currentState: 0, taskAhead: 0, renderAhead: 0 });
    const modelFullRef = useRef(null);
    const [_, setUpdateGenerateState] = useState<boolean>(false);   // 用于更新generateState
    const [isUeFullScreen, setIsUeFullScreen] = useState<boolean>(false);
    const [triggerPollingTask, setTriggerPollingTask] = useState<boolean>(false);
    const [triggerGetModelURL, setTriggerGetModelURL] = useState<boolean>(false);
    const [triggerConnectWS, setTriggerConnectWS] = useState<boolean>(false);
    const fullScreenBtnRef = useRef<HTMLButtonElement>(null);
    const [isModelFullscreen, { enterFullscreen: enterModelFullscreen, exitFullscreen: exitModelFullscreen }] = useFullscreen(modelFullRef);
    const notifiKey = useId();
    const [ueClosed, setUeClosed] = useState<boolean>(false);
    const [historyCoord, setHistoryCoord] = useState<Array<VillagePos>>([]);
    const reconnectionCount = useRef<number>(0);
    const [waitlist, setWaitlist] = useState<Wait>({ text: "", status: 0 });
    const [showModal, setShowModal] = useState<number>(0);

    useEffect(() => {
        /**
         * 判断是否是从历史页面进来的，
         * 如果是历史记录进行查看
         * 则执行historyTaskView
        */
        const historyTaskId = searchParams.get('id');
        historyTaskId && historyTaskView();

        const script = document.createElement('script');
        script.src = './js/mp4box.all.min.js';
        script.async = true;
        document.body.appendChild(script);

        // 阻止使用空格键控制全屏
        const blockFullScreen = (event: KeyboardEvent) => {
            if (event.code === 'Space' && document.activeElement === fullScreenBtnRef.current) event.preventDefault();
        };
        document.addEventListener('keydown', blockFullScreen);
        return () => {
            document.removeEventListener('keydown', blockFullScreen);
            document.body.removeChild(script);
            notification.destroy(notifiKey);
            timerRef.current && clearInterval(timerRef.current);
        };
    }, []);

    useEffect(() => {
        async function waitlist() {
            let data;
            let content = "";
            try {
                data = await checkUserWaitlist();
                if (data.name) {
                    setWaitlist({ status: 0, text: "You are now on our waiting list, thanks" });
                };
                await getTaskList();
            } catch (e: any) {
                if (data.name) {
                    content = "You are now on our waiting list, thanks";
                }
                setWaitlist({ text: content, status: e.status })
            }
        }
        waitlist();
    }, [])


    // 处理查看历史记录预览
    const historyTaskView = async () => {
        const historyTaskId = searchParams.get('id');
        if (!historyTaskId) return
        var response;
        try {
            response = await taskStatus(historyTaskId);
            var pos = await getVillageFile(historyTaskId);
            setHistoryCoord(pos.data.village);

        } catch (error) {
            message.error("Error in querying task status!");
        }
        var status = response?.data.status;
        if (status === undefined) return
        status = parseInt(status);

        if (status >= 210) {
            // 加载历史记录中的模型
            var modelURL = ASSET_MODEL + historyTaskId + "/scene.fbx";
            setModelUrl(modelURL);
        }
        // 从历史记录进来以后同步当前页面中taskid和generate-state
        taskid.current = historyTaskId!;
        generateState.current.currentState = status;
        taskTempStatus.current = status;

        // 在画板上绘制历史记录中的图片
        let mapURL = import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_ASSET_FILE + historyTaskId + "/map.png";

        let image = await loadImage(mapURL);
        drawBoardRef.current?.canvasClear();
        drawBoardRef.current?.boardDrawImage(image);
        drawBoardRef.current?.addChooseImage();

        if (status === 100 || status === 200) {
            setGenerateLoading(true);
            disabledButton();
            setTriggerPollingTask(!triggerPollingTask);
        }

        if (status === 360) {
            setTriggerConnectWS(!triggerConnectWS);
        }

        if (status === 220 || status === 230 || status === 260 || status === 300) {
            disabledButton();
            queryType.current = "ue";
            setGenPersceneLoading(true);
            setTriggerPollingTask(!triggerPollingTask);
        }
    }

    // 从历史记录中快速生成UE场景，当status是900时
    const historyGenerateScene = async () => {
        if (generateState.current.time || generateState.current.value) {
            generateState.current.time = 0;
            generateState.current.value = 0;
        }
        disabledButton();
        try {
            await taskRender(taskid.current);
            setGenPersceneLoading(true);
            setTriggerPollingTask(!triggerPollingTask);
        } catch (error) {
            console.error(error);
            message.error("Error in generating UE scene!");
        }
        //enabledButton();
    }

    const idleTip = () => {
        Modal.confirm({
            title: "Tips",
            content: `You have been inactive for a while, are you still there? 
            The 3D environment will automatically close to free up resources.`,
            okText: "I am here",
            cancelText: "Close task",
            onOk: reconnect,
            onCancel: closeWS
        });
    }

    const reconnect = async () => {
        setTaskID("");
        if (reconnectionCount.current >= 2) {
            reconnectionCount.current = 0;
            idleTip();
            return
        };
        setPerfectionScene(false);
        reconnectionCount.current += 1;
        setTriggerConnectWS(!triggerConnectWS);
    }

    const tryToReconnect = () => {
        return <button onClick={() => {
            setTriggerConnectWS(!triggerConnectWS);
        }}>Try to reconnect</button>
    };

    // 触发轮询任务
    useUpdateEffect(() => {
        timerRef.current && clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
            queryStatusTask(taskid.current);
        }, 2000);
    }, [triggerPollingTask]);

    // 触发获取模型
    useUpdateEffect(() => {
        getModelUrl(taskid.current);
    }, [triggerGetModelURL]);

    // 触发链接websocket
    useUpdateEffect(() => {
        // notification.destroy(notifiKey);
        async function connect() {
            var response;
            try {
                response = await taskStatus(taskid.current);
            } catch (error) {
                // @ts-ignore
                console.log("error:", error.message);
                reconnectUESceneNotice(tryToReconnect, notifiKey);
            }

            if (response?.data.status === "360") {
                setTaskID(taskid.current);
                setPerfectionScene(true);
                notification.destroy(notifiKey);
            }

            if (response?.data.status === "900") {
                generateState.current.currentState = 900;
                setUpdateGenerateState(prev => !prev);
                historyGenerateScene();
                notification.destroy(notifiKey);
            }

        }
        connect();
    }, [triggerConnectWS]);

    // 示例图片中选择一张图片
    const selectImage = async (index: number) => {
        await initStatusOfModelAreaAndSceneArea();
        drawBoardRef.current?.canvasClear();
        drawBoardRef.current?.clearVillage();
        let url = getImageSrcById(index, initImages);
        let image = await loadImage(url);
        drawBoardRef.current?.boardDrawImage(image);
        drawBoardRef.current?.addChooseImage();
        setActiveImageIndex(index);
        
    }

    const initStatusOfModelAreaAndSceneArea  = async () => {
        if (generateLoading || genPersceneLoading) return;
        // 当前生成模型任务完毕时，用户要切换任务（图片）时，给用户提示确认是否切换
        if (generateState.current.currentState >= 210) {
            var isSwitchTask = await taskSwitchConfirm();
            if (isSwitchTask) {
                genPersceneLoading && setGenPersceneLoading(false);
                modelUrl && setModelUrl("");
            } else {
                return
            }
        }

        /**
         * 这里判断生成模型后切换任务如果给用户确认提示后
         * 就不再弹出修改后的确认弹窗，以便不好的用户体验
         */
        if (!isSwitchTask) {
            // 如果用户有绘画记录，在切换图片时给用户确认提示
            let step = drawBoardRef.current?.step();
            let isSwitchImage: boolean = false;
            if (step && step > 0) {
                isSwitchImage = await switchImageConfirm();
            } else {
                isSwitchImage = true;
            }
            if (!isSwitchImage) return
        }

        generateState.current.currentState = 0;
        taskTempStatus.current = 0;
        isRevision.current = false;
    }

    //  获取模型URL
    const getModelUrl = async (task_id: string) => {
        var url = ASSET_MODEL + task_id + "/scene.fbx";
        console.log("url", url);
        try {
            var response = await getModelURL(task_id);;
            setGenerateLoading(false);
            clearInterval(timerRef.current);
            response.status === 200 && setModelUrl(url);
            isModelDone.current = false; // 当轮询状态为210时表示模型生成完毕
        } catch (error) {
            clearInterval(timerRef.current);
            message.error("An error occurred at the server level");
        }
        setGenerateLoading(false);
    }

    // 获取轮询状态
    const queryStatusTask = async (task_id: string) => {
        var response: AxiosResponse;
        var state = generateState.current;
        try {
            response = await taskStatus(task_id);
            var status = parseInt(response.data.status);
            if (status === 100) {
                state.taskAhead = response.data.task_queue_ahead;
            };

            if (status === 210) {
                // 当轮询状态为210时表示模型生成完毕,此时触发模型下载
                setTriggerGetModelURL(!triggerGetModelURL);
                clearInterval(timerRef.current);
                enabledButton();
            };

            if (status === 220 || status === 260) {
                state.taskAhead = response.data.task_queue_ahead;
                state.renderAhead = response.data.render_queue_ahead;
            }

            if (status >= 240 && status < 250) {
                generateState.current.value = 0;
                generateState.current.time = 0;
                setGenPersceneLoading(false);
                setGenerateLoading(false);
                clearInterval(timerRef.current);
                enabledButton();
                message.error({
                    content: "The current image cannot generate a model, please change the image",
                    duration: 10
                });
            };

            if (status === 360) {
                clearInterval(timerRef.current);
                setTriggerConnectWS(!triggerConnectWS); // 触发链接websocket
                setGenPersceneLoading(false);
                enabledButton();
                const audio = document.getElementById('audio') as HTMLAudioElement;
                audio?.play();
            };

            if (status >= 370 && status < 380) {
                clearInterval(timerRef.current);
                enabledButton();
                setGenPersceneLoading(false);
                message.error({
                    content: "An error occurred while generating the UE scene.",
                    duration: 10
                });
            };
            updateState(state, taskTempStatus, queryType.current, status);
            setUpdateGenerateState(prev => !prev);
        } catch (error) {
            setGenerateLoading(false);
            clearInterval(timerRef.current);
            message.error(`An error occurred:, ${(error as AxiosError)?.message}`);
            enabledButton();
        };
    }

    // 生成模型
    const generateModel = async (): Promise<void> => {
        let isNotice = hudiniNotification(taskTempStatus.current);
        if (isNotice) return
        let { imglen, valicount } = drawBoardRef.current?.isColorAllowed()!;  
        if ( imglen - valicount > 2000) {
            message.error("There are colors that do not meet the requirements.");
            return
        }
        navigate(``);
        queryType.current = "model";
        var response: AxiosResponse;
        taskID && closeWS();  // 当再次生成新的模型时关闭
        setGenerateLoading(true);
        modelUrl && setModelUrl("");
        disabledButton();
        // 当轮询状态为210时表示模型生成完毕
        isModelDone.current = true;
        generateState.current.value = 0;
        if (generateState.current.currentState === 210) {
            isRevision.current = true;
        }
        const pos = await drawBoardRef.current?.pos();
        const blob = await drawBoardRef.current?.toBlob();
        const formData = new FormData();
        formData.append('files', blob, 'map.png');
        const jsonObject = {
            village: pos,
        };

        const jsonString = JSON.stringify(jsonObject);

        const jsonBlob = new Blob([jsonString], { type: 'application/json' });
        formData.append('files', jsonBlob, 'data.json');
        try {
            if (isRevision.current) { // true 修改的图片, false 新的图片
                response = await revisionTask(formData, taskid.current);
            } else {
                response = await createTask(formData);
                taskid.current = response.data.task_id;
                localStorage.setItem('task_id', response.data.task_id);
            }
        } catch (error: any) {
            // console.log(error.status);
            if (error.status === 403) {
                setShowModal(showModal + 1);
            }
            setGenerateLoading(false);
            enabledButton();
            return;
        };
        setTriggerPollingTask(!triggerPollingTask);  // 触发轮询任务
    }

    // 展示生成UE场景
    const showPerfectionScene = async (task_id: string) => {
        let isNotice = ueNotification(taskTempStatus.current);
        if (isNotice) return
        queryType.current = "ue";
        setGenPersceneLoading(true);
        disabledButton();
        generateState.current.value = 0;
        try {
            var response = await confirmTask(task_id);
            if (response.status === 200) {
                setTriggerPollingTask(!triggerPollingTask);
            }
        } catch (error) {
            clearInterval(timerRef.current);
            message.error(`An error occurred:, ${(error as AxiosError)?.message}`);
        }
    }

    // 关闭UE连接
    const closeWS = async () => {
        setUeClosed(true);
        try {
            if (generateState.current.currentState >= 360) {
                var response = await closeTask(taskid.current);
                console.log("关闭状态：", response.status);
                generateState.current.currentState = 900;
                setUpdateGenerateState(prev => !prev);
            }
            setTimeout(() => {
                setUeClosed(false);
                setPerfectionScene(false);
            }, 5000);

            setTaskID("");
        } catch (error) {
            message.error(`An error occurred:, ${(error as AxiosError)?.message}`);
        }
    }

    // 定义一个异步函数来更新指定 id 的图像的 src
    const changeImageSrc = async (id: number, newSrc: string) => {
        setImages(prevImages =>
            prevImages.map(image =>
                image.id === id ? { ...image, src: newSrc } : image
            )
        );
    };

    // 获取4张随机图片
    const randomPic = async () => {
        disabledButton();
        setRandomLoading(true);
        setActiveImageIndex(-1);
        let response;
        for (let i = 0; i < initImages.length; i++) {
            try {
                response = await randomMap();
                var src = await loadReader(response?.data);
                changeImageSrc(i, src);
            } catch (error) {
                // @ts-ignore
                break
            }
        }
        enabledButton();
        setRandomLoading(false);
    }

    const buttonStyle = { backgroundColor: generateLoading || randomLoading || genPersceneLoading ? "#ccc" : "" };

    return (
        <main className="py-2 pt-0 flex-1">
            <div className="flex pr-6 pl-6 h-full">
                <div className="flex flex-col">
                    <div className="px-6 py-6 pt-3 rounded-md bg-gradient flex-1">
                        <div className=" text-white font-light flex items-center justify-between pb-2">
                            <p className=" flex items-center">
                                <span className=" w-5 h-5 flex border border-white rounded-xl text-sm justify-center items-center mr-2 bg-[#6442A0]">1</span>
                                <span>Generate your environment with a sketch map</span>
                            </p>
                            <p className=" cursor-pointer" onClick={() => { window.location.href = "/" }}>
                                NewTask
                            </p>
                        </div>
                        <DrawingBoard
                            ref={drawBoardRef}
                            imageListIndex={setActiveImageIndex}
                            disableBrush={generateLoading || genPersceneLoading}
                            taskTempStatus={taskTempStatus}
                            coord={historyCoord}
                            switchImg={initStatusOfModelAreaAndSceneArea}
                        />
                        <div className="flex justify-between pb-2">
                            <div className="flex-1 flex">
                                {
                                    initImages.map((item) => {
                                        return (
                                            <img
                                                key={item.key}
                                                src={item.src}
                                                className={`${activeImageIndex === item.id ? "image-active" : ""} mr-2`}
                                                alt=""
                                                width={40}
                                                height={40}
                                                onClick={() => selectImage(item.id)}
                                            />
                                        );
                                    })
                                }
                                <div className="flex-1 flex justify-between text-sm">
                                    <button
                                        className="random-btn text-white w-24 py-1 rounded-md"
                                        onClick={randomPic}
                                        style={{ backgroundColor: generateLoading || randomLoading || genPersceneLoading ? "linear-gradient(to left, #1953f5 0%, #000000 30%)" : "" }}
                                    >
                                        {randomLoading ? "Loading..." : "Random"}
                                    </button>
                                    <button
                                        className="justify-self-center rounded-md text-black px-4 flex-1 mx-2 bg-white hover:bg-gray-200"
                                        onClick={generateModel}
                                        style={buttonStyle}
                                    >
                                        {generateLoading ? "Generating..." : "Generate 3D preview"}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {waitlist.status === 403 && <p className=" text-red-500">{waitlist.text}</p>}
                    </div>
                    <div className="text-[#666] text-xs">
                        <span>close</span>
                    </div>
                </div>

                <div className="flex-1 pl-4 flex flex-col">
                    <div className={`flex-1 w-full flex flex-col justify-center m-auto bg-[#191919] rounded-md text-[#5E5E5E] mb-4 px-4 pt-0`} ref={modelFullRef}>
                        <div className="flex justify-between">
                            <p className=" flex items-center text-white py-2 text-base font-light">
                                <span className=" w-5 h-5 flex border border-white rounded-xl text-sm justify-center items-center mr-2">2</span>
                                <span>3D preview</span>
                            </p>
                            <div className="flex items-center">
                                <button onClick={() => {
                                    if (!modelUrl) {
                                        message.warning("No files to download.");
                                        return
                                    }
                                    var url = MODEL + taskid.current + `?token=${getLocalToken()}`;
                                    window.open(url, '_blank');
                                }}>
                                    <img src={downloadIcon} alt="" />
                                </button>
                                <button onClick={() => { isModelFullscreen ? exitModelFullscreen() : enterModelFullscreen() }} className="outline-none">
                                    <img src={isModelFullscreen ? fullScreenExitIcon : fullScreenIcon} alt="" className="ml-4 h-5" />
                                </button>
                            </div>
                        </div>
                        {
                            modelUrl ?
                                <ThreeScene
                                    isFull={isModelFullscreen}
                                    modelUrl={modelUrl}
                                />
                                : <div className="flex justify-center items-center flex-col text-sm h-full select-none">
                                    <ModelProgress
                                        loading={generateLoading}
                                        value={generateState.current.value}
                                        waiting={generateState.current.taskAhead}
                                    />
                                </div>
                        }
                        {
                            modelUrl && !isModelFullscreen ? <button className="text-sm px-2 py-2 my-2 rounded-md w-[20rem] text-black bg-white hover:bg-gray-200"
                                onClick={() => showPerfectionScene(taskid.current)}
                                style={buttonStyle}
                            >{genPersceneLoading ? "generating..." : "Generate 3D environment"}</button> : ""
                        }
                    </div>
                    <div className=" flex-1 w-full rounded-md bg-[#191919] text-[#5E5E5E] overflow-hidden">
                        <div className={`text-white text-sm h-full ${isUeFullScreen ? " fixed top-0 left-0 w-full bg-[#191919]" : ""}`}>
                            <div className="flex justify-between pl-4 pt-3 pr-4 pb-3">
                                <div className="flex items-center">
                                    <span className=" w-5 h-5 flex border border-white rounded-xl text-sm justify-center items-center mr-2">3</span>
                                    <span>3D environment</span>
                                </div>
                                <div className="flex items-center">
                                    <button onClick={closeWS}>
                                        Close
                                    </button>
                                    <button
                                        onClick={async () => {
                                            let check = await downloadCheck(taskid.current);
                                            if (check) {
                                                let url = `https://auto3d.blob.core.windows.net/automation-3d/cybever-3d/download.html?env=${import.meta.env.VITE_APP_MODE}&taskid=${taskid.current}&token=${getLocalToken()}&r=${Math.random()}`
                                                window.open(url, "_blank");
                                            }
                                        }}
                                        className="ml-4"
                                    >
                                        <img src={downloadIcon} alt="" />
                                    </button>
                                    <button ref={fullScreenBtnRef} onClick={() => setIsUeFullScreen(!isUeFullScreen)} className="outline-none">
                                        <img src={isUeFullScreen ? fullScreenExitIcon : fullScreenIcon} alt="" className=" h-5 ml-4" />
                                    </button>
                                </div>
                            </div>
                            {perfectionScene && taskID ? <UEScene taskId={taskID} callback={reconnect} /> : ""}
                            <div className="flex justify-center items-center flex-col text-sm h-full select-none text-[#5E5E5E]">
                                <UEProgress
                                    status={generateState.current.currentState}
                                    value={generateState.current.value}
                                    total_time={generateState.current.total_time}
                                    btnStyle={buttonStyle}
                                    loading={genPersceneLoading}
                                    genScene={historyGenerateScene}
                                    taskAhead={generateState.current.taskAhead}
                                    renderAhead={generateState.current.renderAhead}
                                    isClosed={ueClosed}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-[#666] text-xs space-x-2">
                        <span>status: {generateState.current.currentState}, time:{generateState.current.time}s, {generateState.current.text}</span>
                        <span>task id:{taskid.current}</span>
                    </div>
                </div>
            </div>
            <WaitList isOpen={showModal} />
            <audio id="audio" className="hidden" src="/y1561.wav" preload="auto"></audio>
        </main>
    );
}