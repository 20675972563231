import YouTube from 'react-youtube';

let vid = ["P5HAZ6z4zo4", "XkY5j8gzEf4", "uUqzCxnEURQ", "MbrF6EuwdtQ"];

function Video() {
    return (
        <div className=' p-10 flex flex-wrap'>
            {
                vid.map((id, i) => {
                    return <YouTube videoId={id} opts={{ height: '240', width: '412' }} key={i} className=" mr-5 mb-5" />
                })
            }
        </div>
    );
};

export default Video;