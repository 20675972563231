import { formatDateString } from "@/lib/utils";
import { TaskCardProps } from "@/types";
import villageIcon from "@/assets/village1.svg";
import "./taskCard.css";
import ImageComponent from "../FallbackSrc/FallbackSrc";

const taskProgress: { [key: number]: string } = {
    100: "Generating Model",
    200: "Generating Model",
    210: "Model completed",
    220: "Waiting",
    230: "Generating .geo files",
    241: "Failed",
    242: "Failed",
    260: "Waiting",
    300: "Generating scene",
    360: "Scene executing",
    371: ".geo file download failed",
    900: "Ready"
};

const fontColor = (status: number) => {
    if (status === 241 || status === 242) return { color: "red" }
    if (status === 900) return { color: "#53D2EE" }
    return {}
}

function TaskCard({ imgSrc, status, taskID, viewClick, deleteClick, coord }: TaskCardProps) {
    return (
        <div className="w-[14rem] p-3 rounded-lg container text-xs">
            <div className=" relative">

                <ImageComponent
                    imgSrc={imgSrc}
                    fallbackSrc={import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_ASSET_FILE + taskID + "/map.png"}
                    alt={taskID} style={{ minHeight: "200px" }} />
                {
                    coord?.length > 0 && coord.map((pos) => {
                        return <img
                            key={pos.vid}
                            src={villageIcon} className=" absolute" style={{ top: `${(pos.y * 100).toFixed(4)}%`, left: `${(pos.x * 100).toFixed(4)}%` }} width={24}
                            height={24} />
                    })
                }
            </div>
            <p className="pt-1 flex justify-between">
                <span style={fontColor(status)} >{taskProgress[status]}</span>
                <span className="date">{formatDateString(taskID)}</span>
            </p>
            <div className="flex space-x-2">
                <button onClick={() => viewClick(taskID, coord)} className="mt-1 py-1 flex-1 rounded-sm view-btn" id={taskID}>View</button>
                <button onClick={() => deleteClick(taskID)} className="mt-1 py-1 flex-1 rounded-sm view-btn">Delete</button>
            </div>
        </div>
    );
}
export default TaskCard;