import { ChangeEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import { auth, goolgeProvider, twitterProvider } from "@/lib/firebase";
import { useDispatch } from "react-redux";
import { setToken } from "@/store/reducer/user";
import { checkUserWhitelist, checkWaitlist, setLocalToken } from "@/lib/utils";
import googleIcon from "@/icon/google.png";
import twitterIcon from "@/icon/twitter.png";
import { Modal } from "antd";


function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [checked, setChecked] = useState(false);
    const [checkMsg, setCheckMsg] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked);
        setCheckMsg(false);
    };

    const loginhandle = async (type: 'google' | 'twitter') => {
        if (!checked) {
            setCheckMsg(true);
            return
        };

        let provider;
        switch (type) {
            case 'google':
                provider = goolgeProvider;
                break;
            case 'twitter':
                provider = twitterProvider;
                break;
            default:
                return;
        }

        try {
            var result = await signInWithPopup(auth, provider);
            const user = result.user;
            let token = await user.getIdToken();
            
            // 检查用户是否在白名单，403不在白名单，200在白名单
            let whitelistReslut = await checkUserWhitelist(token);
            if(whitelistReslut === 403){
                let reslut = await checkWaitlist(token);
                // 判断是否在wait list，如果返回的结果中是{}，表示不再waitlist，如何可以访问name表示已经在waitlist中
                if(reslut.hasOwnProperty("name")){
                    navigate(`/wait?user=${JSON.stringify(reslut)}&token=${token}`);
                }else{
                    navigate(`/wait?user=${JSON.stringify({name:user.displayName})}&token=${token}`);
                }
                return
            }
            localStorage.setItem("email", user.email!);
            localStorage.setItem("photoURL", user.photoURL!);
            localStorage.setItem("username", user.displayName!);
            dispatch(setToken(token));
            setLocalToken(token);
            setTimeout(() => navigate("/"), 0);
        } catch (error: any) {
            const errorCode = error.code;
            if(errorCode === "auth/account-exists-with-different-credential"){
                Modal.warning({
                    title: 'Tips',
                    content: 'You have already used another login method with this email, please login using the previous method.',
                    maskClosable: true
                  });
                
            }
        }
    }

    return (
        <div className=" w-full h-full flex justify-center items-center text-white">
            <div className="text-center">
                <img src="/image/logo.png" alt="" width={300} className=" mb-10" />
                <button
                    className="w-full rounded-lg py-1 border border-1 border-gray-800 flex items-center justify-center hover:bg-gray-800 transition duration-300"
                    onClick={() => loginhandle("google")}
                >
                    <img src={googleIcon} alt="" width={30} height={30} />&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>Google Login</span>
                </button>

                <button
                    className="w-full rounded-lg py-1 border border-1 border-gray-800 flex items-center justify-center hover:bg-gray-800 transition duration-300 mt-2"
                    onClick={() => loginhandle("twitter")}
                >
                    <img src={twitterIcon} alt="" width={30} height={30} />&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>Twitter Login</span>
                </button>
                <div className=" mt-4 text-sm max-w-80">
                    <p className="flex items-start">
                        <input
                            type="checkbox"
                            checked={checked}
                            onChange={handleChange}
                            id="agreement"
                            className={`mr-2 ${!checkMsg ? "" : "verification-checkbox"} mt-1`}
                        />
                        <label htmlFor="agreement" className=" text-left">I agree to receive communications from Cybever in accordance with Cybever's &nbsp;<Link className="underline text-blue-300" to="https://www.cybever.ai/privacypolicy" target="_blank">Privacy Policy</Link>&nbsp; and I understand and agree to the terms of Cybever's &nbsp;
                            <Link to="/agreement" target="_blank" className="underline text-blue-300">Beta Test Agreement.</Link></label>
                    </p>

                </div>
                <div className="text-sm text-red-500 flex justify-end h-6">
                    {!checkMsg ? "" : "Please read the agreement and check the box."}
                </div>
            </div>
        </div>
    )
}

export default Login;