import { ReactNode } from "react";
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
    children: ReactNode;
    isAuthenticated: boolean;
  }

const PrivateRoute = ({ children, isAuthenticated }: PrivateRouteProps) => {
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
