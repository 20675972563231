import type { RootState } from "./store/store.ts";
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { Routes, Route, useLocation } from 'react-router-dom';
import Generate from "./pages/Generate/Generate.tsx";
import Header from './components/Header.tsx';
import PrivateRoute from './components/PrivateRoute.tsx';
import Login from './pages/Login/Login.tsx';
import History from './pages/History/History.tsx';
import Agreement from "./pages/UserAgreement/Agreement.tsx";
import Video from "./pages/Video/Video.tsx";
import { useEffect } from "react";
import WaitList from "./pages/WaitList/WaitList.tsx";


export default function App() {
  const token = useSelector((state: RootState) => state.user.token);
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(import.meta.env.VITE_APP_TRACKING_ID);
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: document.title
    });
  }, [location]);

  return (
    <main className="h-screen flex flex-col">
      {token ? <Header /> : ""}
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute isAuthenticated={token != ""}>
              <Generate />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/agreement" element={<Agreement />} />
        <Route path="/history" element={
          <PrivateRoute isAuthenticated={token != ""}>
            <History />
          </PrivateRoute>
        }
        />
        <Route path="/video" element={
          <PrivateRoute isAuthenticated={token != ""}>
            <Video />
            </PrivateRoute>
          } 
        />
        <Route path="/wait" element={<WaitList />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </main>
  );
}
