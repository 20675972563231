import YouTube from 'react-youtube';
import logo from "@/icon/new_logo.png";
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import "./waitlist.css";
import { saveDefaultWaitList, saveWaitList } from '@/lib/utils';
import { message } from 'antd';


function WaitList() {
    const [searchParams] = useSearchParams();
    const [form, setForm] = useState({ "name": "", "organization_name": "", "memo": "", "role": "" });
    const [formTitle, setFormTitle] = useState("Please help us know you better and shorten your wait.");
    const [isSubmit, setIsSubmit] = useState(false);
    const [jumpCount, setJumpCount] = useState(30);
    const timer = useRef<null | NodeJS.Timeout>(null);
    const token = searchParams.get('token');

    useEffect(() => {
        const user = searchParams.get('user');
        if (user) {
            var userInfo = JSON.parse(user);
            setForm({ ...form, ...userInfo });
        }

        if (userInfo.hasOwnProperty("role") && userInfo.name) {
            // 提交过了
            setFormTitle(`Welcome, ${userInfo.name}. Thank you for your interest.`);
            setIsSubmit(true);
        } else {

            // 未提交过
            saveDefaultWaitList(token!)
                .then((_) => {
                    setIsSubmit(false);
                    setFormTitle("Please help us know you better and shorten your wait.");
                })
        }
        return () => { timer.current && clearInterval(timer.current); }
    }, []);

    useEffect(() => {
        if (jumpCount < 1) {
            window.location.href = "https://www.cybever.ai/";
            timer.current && clearInterval(timer.current);
        };
    }, [jumpCount]);


    const nameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, name: e.target.value });
    }

    const organizationChange = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, organization_name: e.target.value });
    }

    const textareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setForm({ ...form, memo: e.target.value });
    }

    const roleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, role: e.target.value });
    };

    const submit = async () => {
        if (form.name === "") {
            message.warning("Name is a required field.");
            return
        }
        let newForm = { name: form.name, organization_name: form.organization_name, memo: form.memo, role: form.role };
        try {
            let result = await saveWaitList(newForm, token!);

            if (result.result === 1) {
                message.success("Successfully added to waiting list.");
                setFormTitle(`Welcome, ${form.name}. Thank you for your interest.`);
                setIsSubmit(true);
                return
            }

            message.success("You are now on our waiting list, thanks");
        } catch (e) {
            message.error("Application failed!");
        }
        setIsSubmit(true);
    }

    const edit = () => {
        setIsSubmit(false);
        setFormTitle("Let us know you better and fasten your waiting");
        setJumpCount(30);
        timer.current && clearInterval(timer.current);
    }

    return (
        <>
            <header className="text-white flex items-center px-5 py-2 cursor-pointer" onClick={() => window.location.href = "https://www.cybever.ai/"}>
                <img src={logo} alt="" width={22} height={22} />
                <p className=" text-2xl ml-5">Cybever</p>
            </header>
            <main className=' flex flex-col justify-between flex-1'>
                <div className=" text-white sm:w-[65rem] w-full mx-auto px-4 sm:px-0">
                    <h2 className=" text-5xl ">You have joined the waitlist. Your spot is saved!</h2>
                    <h4 className=" text-3xl mt-3 mb-4">We'll notify you as soon as it's available.</h4>
                </div>
                <div className="flex flex-col sm:flex-row justify-center text-white p-5">
                    <div className="list sm:w-[35rem] w-full p-5 bg-[#191919] rounded-xl sm:mr-6">
                        <p className=' text-xl mb-3'>{formTitle}</p>
                        <p className='flex flex-col mb-4'>
                            <label htmlFor="" className='text-white opacity-50'>Name *</label>
                            {
                                isSubmit ? <span className='h-8'>{form.name}</span> :
                                    <input type="text" className=' h-8 rounded-sm outline-none bg-white bg-opacity-20 text-white px-2' onChange={nameChange} value={form.name} />
                            }
                        </p>
                        <p className='flex flex-col mb-4'>
                            <label htmlFor="" className='text-white opacity-50'>Organization (optional)</label>
                            {
                                isSubmit ? <span className='h-8'>{form.organization_name}</span> :
                                    <input type="text" className=' h-8 rounded-sm outline-none bg-white bg-opacity-20 text-white px-2' onChange={organizationChange} value={form.organization_name} />
                            }
                        </p>
                        <p className='flex flex-col mb-4'>
                            <label htmlFor="" className='text-white opacity-50'>What’s your role (optional)</label>
                            {isSubmit ? <span className='h-8'>{form.role}</span> :
                                <input type="text" className=' h-8 rounded-sm outline-none bg-white bg-opacity-20 text-white px-2' onChange={roleChange} value={form.role} />
                            }
                        </p>
                        <p className='flex flex-col mb-4'>
                            <label htmlFor="" className='text-white opacity-50'>Why do you want to use this tool? (optional)</label>
                            {
                                isSubmit ? <span className=' h-28'>{form.memo}</span> :
                                    <textarea className='h-28 rounded-sm outline-none bg-white bg-opacity-20 text-white px-2' onChange={textareaChange} value={form.memo} />
                            }
                        </p>
                        {isSubmit ?
                            <><button onClick={edit} className='eidt mr-6'>Edit</button><Link to="https://www.cybever.ai/" className=' underline'>Cybever.ai</Link></> :
                            <button className='submit w-full bg-blue-400 h-8 rounded-md' onClick={submit}>Submit</button>}
                    </div>
                    <div className="tutorial sm:w-[55rem] w-full p-5 bg-[#191919] rounded-xl sm:mt-0 mt-4">
                        <YouTube videoId="uUqzCxnEURQ" opts={{ height: '100%', width: '100%' }} className=" w-full h-60 sm:h-full sm:w-full" />
                    </div>
                </div>
                <div className=' flex justify-center items-center my-10'>
                    <a href="https://discord.gg/nSs5EWgYVR" className="herobutton withpic w-inline-block">
                        <span className=" text-white">Join Discord</span>
                        <img src="https://cdn.prod.website-files.com/664b9c0879520ba8a10b73bc/66c78f127d7434c8d53c9a6b_Discord.svg" loading="lazy" alt="" className="image-2223" />
                    </a>
                </div>
                <div className='flex sm:justify-between justify-center text-white text-opacity-60 sm:px-10 sm:py-3 px-1 pb-10 flex-1 items-end text-sm sm:text-base'>
                    <span className='sm:block hidden'>We are Cybever LTD, 2024</span>
                    <span>Privacy policy/Terms of use</span>
                    <span className='sm:block hidden'>© 2024 Cybever. All rights reserved.</span>
                </div>
            </main>
        </>
    );
};
export default WaitList;